const ws_test_env='ws://47.100.65.30:8088';
const ws_production_env='wss://unid.ultron-soft.com';

const isSHowAllPage=true;//侧边栏展示所有目录

//测试环境
const test_env={
    baseURL: 'api3',
    base_wsURL: ws_test_env,
    isSHowAllPage,
}
//生产环境
const production_env={
    baseURL:'api2',
    base_wsURL:ws_production_env,
    isSHowAllPage:false,
}
//邓帅机
const production_env_local={
    baseURL:'api1',
    base_wsURL:ws_production_env,
    isSHowAllPage,
}


export default production_env;
