<template>
    <div class="ul-modal" @click.stop="" v-if="isShow">
            <div class="title">{{title}}</div>
            <slot>内容</slot>
            <div class="footer">
                <button class="ulbutton-blue" @click="cancel">取消</button>
                <button class="ulbutton-yellow" @click="ok">确定</button>
            </div>
            
    </div>
  </template>
  
  <script>
  export default {
    name: "Pager",
    components: {},
    props: {
      title:{
          type:String,
          default:'title',
      },
      isShow: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
      }
    },
    methods: {
        hide() {
          console.log('hide')
            this.$emit('hide');
        },
        cancel() {
          this.hide();

        },
        ok() {
          this.hide();
        }
    },
    mounted() {
                let params = {'page':this.nowPage,'pageSize':this.pageSize};
                console.log('pager emit loadData')
                this.$emit('loadData',params);
    },
    watch: {
        nowPage() {
          let params = {'page':this.nowPage,'pageSize':this.pageSize};
                        console.log('pager emit loadData')
          this.$emit('loadData',params);
        }
    }
  };
  </script>
  
  <style lang="less" scoped>
    .main {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 99999;
    }
.ul-modal {
  color: #ffffff;
  font-size: 18px;
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-image-source: url(~@/assets/modalbg.png);
            border-image-slice: 200 50 100 50 fill;
            border-top: 80px solid;
            border-right: 20px solid;
            border-left: 30px solid;
            border-bottom: 50px solid;
            width: 656px;
            min-height: 400px;
            position: relative;
            padding: 100px 50px 50px 50px;
}
.title {
    height: 37px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F9FCFF;
    line-height: 37px;
    text-shadow: 0px 0px 7px rgba(180,218,251,0.8);
    position: absolute;
    top: -10px;
    left: 35px;
}
.footer {
    position: absolute;
    left: 100px;
    bottom: 20px;
}
  </style>
  