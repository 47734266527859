import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import dataV from '@jiaminghi/data-view'
import { Image } from 'element-ui'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import './style/style.less'
import "./style/tableStyle.scss";
import ULModal from './components/ul-modal'
import ULModal2 from './components/ul-modal2'
import pageHeader from './components/pageHeader'
Vue.component('ul-modal', ULModal)
Vue.component('ul-modal2', ULModal2)
Vue.component('ul-page-header', pageHeader)
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
VXETable.use(VXETablePluginExportXLSX)
Vue.use(VXETable)
Vue.use(Image);
Vue.use(dataV)
Vue.use(Antd);
Vue.config.productionTip = false

new Vue({

  beforeCreate(){
    //安装事件总线
    Vue.prototype.$bus=this
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
