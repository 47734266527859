<template>
    <div class="ul-modal" @click.stop="" v-show="visible">
            <div class="title">{{title}}</div>
            <slot>内容</slot>
            <div class="footer">
                <button class="ulbutton-blue" @click="handleCancel">{{ cancelText }}</button>
                <button class="ulbutton-yellow" @click="handleOk">{{ okText }}</button>
            </div>
            
</div>
  </template>
  
  <script>
  export default {
    name: "Pager",
    components: {},
    model: {
      prop: 'visible',
      event: 'cancel'
    },
    props: {
      okText:{
        type:String,
        default:'确定',
      },
      cancelText:{
        type:String,
        default:'取消',
      },
      title:{
          type:String,
          default:'title',
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
      }
    },
    methods: {

      handleCancel() {
          this.$emit('cancel',false);

        },
      handleOk() {
          this.$emit('ok');
        }
    },

  };
  </script>
  
  <style lang="less" scoped>

.ul-modal {
  color: #ffffff;
  font-size: 18px;
    position: fixed;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    border-image-source: url(~@/assets/modalbg.png);
            border-image-slice: 200 50 100 50 fill;
            border-top: 80px solid;
            border-right: 20px solid;
            border-left: 30px solid;
            border-bottom: 50px solid;
            width: 656px;
            min-height: 400px;
            padding: 100px 50px 50px 50px;
}
.title {
    height: 37px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F9FCFF;
    line-height: 37px;
    text-shadow: 0px 0px 7px rgba(180,218,251,0.8);
    position: absolute;
    top: -10px;
    left: 35px;
}
.footer {
    position: absolute;
    left: 100px;
    bottom: 20px;
}
  </style>
  